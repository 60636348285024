import React, { useState } from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@mui/material'
import PropTypes from 'prop-types'
import { isEmpty, find } from 'lodash-es'

/**
 * Accepts schema with an array of objects of value + label pairs and renders a multiselect component.
 * EX:
 * "items": {
        "options": [
            {
                "label":"placeholder1",
                "value":"value placeholder1"
            },
            {
                "label":"placeholder2",
                "value":"value placeholder2"
            },
            {
                "label":"placeholder3",
                "value":"value placeholder3"
            }
        ]
    }

 * We avoid the enum prop because it automatically sets an 'allowedValues' validation. Which fails
 * because we are saving a value and not the object.
 * @param props
 * @returns {*}
 * @constructor
 */
const MaterialEnumField = (props) => {
    const { handleChange, path, schema, uischema, data, errors } = props
    // We use options over enums because using enums invokes a custom validation called allowedValues.
    // Where list of objects in the schema are the 'allowedValues'. But since we save option.value and not the
    // object itself, the validation fails.
    const options = schema.items?.options

    // Filter values that are no longer an option in the schema. (from updated dynamic configs)
    const valueExistsInSchema = (value) => {
        return find(options, { value: value })
    }
    const [values, setValues] = useState(
        !isEmpty(data) ? data.filter(valueExistsInSchema) : []
    )

    const isValid = errors.length === 0
    const formHelperText = !isValid ? errors : null

    const doChange = (event) => {
        // We want to filter on change to prevent users from injecting their own options thru the html.
        const filteredValues = event.target.value.filter(valueExistsInSchema)
        setValues(filteredValues)
        handleChange(path, filteredValues)
    }

    const renderValues = (selected) => {
        return selected
            ? selected.map((value, idx) => {
                  const selectedValue = find(options, { value: value })
                  return (
                      <div key={idx}>
                          {' '}
                          {selectedValue && selectedValue.label}{' '}
                      </div>
                  )
              })
            : null
    }

    return (
        <FormControl variant="standard" sx={{ minWidth: '225px' }}>
            <InputLabel error={!isValid}>{schema.hintText}</InputLabel>
            <Select
                variant="standard"
                sx={{
                    whiteSpace: 'normal',
                }}
                MenuProps={{
                    style: { zIndex: 10000 },
                }}
                value={values}
                onChange={doChange}
                multiple={true}
                label={uischema.label || ''}
                renderValue={renderValues}
            >
                {options?.map((option) => {
                    return (
                        <MenuItem
                            value={option.value}
                            key={option.value}
                            children={option.label}
                        />
                    )
                })}
            </Select>
            <FormHelperText error={!isValid}>{formHelperText}</FormHelperText>
        </FormControl>
    )
}

MaterialEnumField.propTypes = {
    path: PropTypes.string,
    handleChange: PropTypes.func,
    schema: PropTypes.object,
}

export default withJsonFormsControlProps(MaterialEnumField)
