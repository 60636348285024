import React from 'react'
import { ListBase, ListToolbar } from 'react-admin'
import ListPagination from './ListPagination'

/**
 * A Pandium styled List component that includes the TopToolbar, Divider, Pagination.
 * @param children
 * @param TopToolbar
 * @param perPage
 * @param filters (react component to filter list)
 * @returns {*}
 */
export default ({ children, TopToolbar, filters, perPageCustom, ...props }) => {
    return (
        <ListBase
            disableSyncWithLocation
            title={false}
            resource={props.resource}
            sort={props.sort}
            perPage={props.perPage}
            filter={props.filter}
            exporter={false}
            queryOptions={{
                keepPreviousData: false,
            }}
        >
            {TopToolbar && <TopToolbar />}
            {(props.actions || filters) && (
                <ListToolbar actions={props.actions} filters={filters} />
            )}{' '}
            {children}
            <ListPagination perPageCustom={perPageCustom} />
        </ListBase>
    )
}
