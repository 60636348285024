import React from 'react'
import { connect } from 'react-redux'
import { MenuList, Button, IconButton, Divider, Tooltip } from '@mui/material'
import RASidebar from './RASidebar'
import MenuCloseIcon from './MenuCloseIcon.svg'
import {
    MenuItemLink,
    useResourceDefinitions,
    useSidebarState,
} from 'react-admin'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import FormatPaintIcon from '@mui/icons-material/FormatPaint'
import ExtensionIcon from '@mui/icons-material/Extension'
import PeopleIcon from '@mui/icons-material/People'
import AssessmentIcon from '@mui/icons-material/Assessment'
import AppsIcon from '@mui/icons-material/Apps'
import { stringify } from 'query-string'
import {
    TENANTS_PER_PAGE,
    RUNS_PER_PAGE,
    PARTNERS_PER_PAGE,
} from '../../appConfigs'

const menuStyles = {
    MenuItemLink: {
        color: 'rgba(255,255,255,0.60)',
        padding: '0 0 0 20px',
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        lineHeight: '28px',
        opacity: '.6',
        height: '56px',
        margin: '0',
        '&:hover:hover': {
            opacity: '.7',
        },
        '&.RaMenuItemLink-active': {
            color: '#FFF',
            opacity: '1',
            borderLeft: '4px',
            borderStyle: 'solid',
            borderImage: 'linear-gradient(to bottom, #9D74F9, #79CCFF) 1 100%',
            '&:hover:hover': {
                opacity: '1',
            },
        },
    },
    MenuItemTitle: {
        fontFamily: 'RobotoCondensedRegular',
        fontSize: '20px',
        lineHeight: '28px',
        padding: '0',
        margin: '20px 0 0 20px',
        color: '#E1E1E1',
        outline: 'none',
        opacity: '.5',
    },
    MenuList: {
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        color: '#FFF',
    },
    menuItemLinkRoot: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    helpLinkHeightPartner: {
        '@media (maxHeight: 500px)': {
            position: 'relative',
        },
        position: 'fixed',
    },
    helpLinkHeightParent: {
        '@media (maxHeight: 757px)': {
            position: 'relative',
        },
        position: 'fixed',
    },
    helpLinkWrapper: {
        height: '75px',
        bottom: '0',
        borderRadius: '0',
        opacity: '.6',
    },
    helpLinkButton: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        color: '#D8D8D8',
        opacity: '.6',
        textTransform: 'none',
        padding: '0 0 0 20px',
    },
    buttonCollapsed: {
        padding: '0 0 0 15px',
    },
    helpButtonCollapsed: {
        padding: '0',
    },
    helpLinkIcon: {
        margin: '0 5px 0 0',
    },
    helpLinkDivider: {
        width: '250px',
        backgroundColor: '#979797',
        opacity: '.3',
        margin: '0 0 15px 0',
    },
    helpLinkDividerCollapsed: {
        width: '60px',
    },
    collapseButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0 0 10px 0',
    },
    iconButtonCollapsed: {
        width: '100%',
    },
    divider: {
        margin: '40px 0',
        backgroundColor: '#979797',
        opacity: '.3',
    },
}

const Menu = ({
    entitlements,
    resources,
    onMenuTap,
    onMenuClick,
    orgId,
    isParentOrg,
    ...props
}) => {
    const [open, setOpen] = useSidebarState()
    const toggleSidebar = () => setOpen(!open)
    const isLiteAccount = entitlements?.level === 'lite'

    const SideBarLink = ({ to, Icon, text, disabled }) =>
        disabled ? (
            <Tooltip
                PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            fontSize: '14px',
                            padding: '8px ',
                        },
                    },
                }}
                title={
                    <div>
                        <p>
                            <b>Feature Locked!</b> To access this feature, you
                            need to upgrade your platform tier. Learn more about
                            our plans{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={'https://www.pandium.com/upgrade'}
                            >
                                here!
                            </a>
                        </p>
                    </div>
                }
                aria-label="add"
                placement="right-start"
            >
                <Button
                    sx={{ textTransform: 'inherit', padding: 0, margin: 0 }}
                >
                    <MenuItemLink
                        to={'/notfound'}
                        leftIcon={<Icon style={menuStyles.icon} />}
                        primaryText={open ? text : ''}
                        onClick={onMenuTap}
                        sx={{
                            ...menuStyles.MenuItemLink,
                            ...(!open ? menuStyles.buttonCollapsed : {}),
                        }}
                        sidebarIsOpen={open}
                        disabled={true}
                    />
                </Button>
            </Tooltip>
        ) : (
            <MenuItemLink
                to={to}
                leftIcon={<Icon style={menuStyles.icon} />}
                primaryText={open ? text : ''}
                onClick={onMenuTap}
                sx={{
                    ...menuStyles.MenuItemLink,
                    ...(!open ? menuStyles.buttonCollapsed : {}),
                }}
                sidebarIsOpen={open}
            />
        )

    return (
        <MenuList sx={{ root: menuStyles.MenuList }} {...props}>
            <span style={menuStyles.collapseButtonContainer}>
                <IconButton
                    onClick={toggleSidebar}
                    sx={!open ? menuStyles.iconButtonCollapsed : {}}
                    size="large"
                >
                    {open ? (
                        <MenuOpenIcon sx={menuStyles.icon} />
                    ) : (
                        <img alt="menuclosedicon" src={MenuCloseIcon} />
                    )}
                </IconButton>
            </span>
            {open && <div style={menuStyles.MenuItemTitle}> MANAGE </div>}
            <SideBarLink
                to="/integrations"
                Icon={ExtensionIcon}
                text="Integrations"
            />
            <SideBarLink
                to={{
                    pathname: '/tenants',
                    search: stringify({
                        page: 1,
                        perPage: TENANTS_PER_PAGE,
                        sort: 'createdDate',
                        order: 'DESC',
                        filter: {},
                    }),
                }}
                Icon={EmojiPeopleIcon}
                text="Tenants"
            />
            {isParentOrg && (
                <SideBarLink
                    to={{
                        pathname: '/runs',
                        search: stringify({
                            page: 1,
                            perPage: RUNS_PER_PAGE,
                            sort: 'startedDate',
                            order: 'DESC',
                            filter: {},
                        }),
                    }}
                    Icon={SyncAltIcon}
                    text="Runs"
                />
            )}
            {isParentOrg && (
                <SideBarLink
                    to={{
                        pathname: '/partners',
                        search: stringify({
                            page: 1,
                            perPage: PARTNERS_PER_PAGE,
                            sort: 'name',
                            order: 'ASC',
                            filter: {},
                        }),
                    }}
                    Icon={PeopleIcon}
                    text="Partners"
                    disabled={isLiteAccount}
                />
            )}
            {open ? (
                <div style={menuStyles.MenuItemTitle}> SETUP</div>
            ) : (
                <Divider sx={menuStyles.divider} />
            )}
            {isParentOrg && (
                <SideBarLink
                    to="/settings"
                    Icon={FormatPaintIcon}
                    text="Marketplace"
                    disabled={isLiteAccount}
                />
            )}
            <SideBarLink
                to="/account"
                Icon={SettingsApplicationsIcon}
                text="Settings"
            />
            {isParentOrg && (
                <SideBarLink
                    to="/nativeapps"
                    text="Native Apps"
                    Icon={AppsIcon}
                />
            )}
            {open && isParentOrg && (
                <div style={menuStyles.MenuItemTitle}> ANALYTICS</div>
            )}
            {isParentOrg && (
                <SideBarLink
                    to="/metrics"
                    Icon={AssessmentIcon}
                    text="Site Metrics"
                    disabled={isLiteAccount}
                />
            )}
            <div
                style={{
                    ...menuStyles.helpLinkWrapper,
                    ...(isParentOrg
                        ? menuStyles.helpLinkHeightParent
                        : menuStyles.helpLinkHeightPartner),
                }}
            >
                <Divider
                    sx={{
                        ...menuStyles.helpLinkDivider,
                        ...(!open ? menuStyles.helpLinkDividerCollapsed : {}),
                    }}
                />
                <Button
                    sx={{
                        ...menuStyles.helpLinkButton,
                        ...(!open ? menuStyles.helpButtonCollapsed : {}),
                    }}
                    onClick={() =>
                        window.open('https://docs.pandium.com/', '_blank')
                    }
                >
                    <LiveHelpIcon sx={menuStyles.helpLinkIcon} />
                    {open && 'Need Help?'}
                </Button>
            </div>
        </MenuList>
    )
}

const sideBarStyles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '100vh',
        backgroundColor: '#272A32',
        left: '0',
        bottom: '0',
        '& div': {
            overflow: 'hidden',
        },
        zIndex: '1000',
        marginTop: '3.7em',
    },
}

const SideBar = ({
    onMenuTap,
    dispatch,
    isParentOrg,
    orgId,
    entitlements,
    ...props
}) => {
    const resourcesDefinitions = useResourceDefinitions()
    const resources = Object.keys(resourcesDefinitions).map(
        (name) => resourcesDefinitions[name]
    )
    return (
        <RASidebar {...props} sx={sideBarStyles.main}>
            <Menu
                entitlements={entitlements}
                resources={resources}
                onMenuTap={onMenuTap}
                isParentOrg={isParentOrg}
                orgId={orgId}
                {...props}
            />
        </RASidebar>
    )
}

const mapStateToProps = (state) => {
    return {
        isParentOrg: state.org.isParentOrg,
        orgId: state.org.orgId,
        entitlements: state.org.entitlements,
    }
}

export default connect(mapStateToProps)(SideBar)
