import React from 'react'
import { Tabs, Tab } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const partnerTabBarStyles = makeStyles({
    tab: {
        borderBottom: 'solid 2px #DBDEE5',
        width: '15%',
    },
    tabsIndicator: {
        backgroundColor: '#635ee7',
    },
    tabsRoot: {
        margin: '15px 0 24px 0',
    },
    tabRoot: {
        '&:disabled': {
            color: '#635ee7',
            opacity: '1',
        },
        color: '#635ee7',
        opacity: '1',
    },
})
export default ({ handleTabChange, tabValue }) => {
    const classes = partnerTabBarStyles()
    return (
        <Tabs
            classes={{
                indicator: classes.tabsIndicator,
                root: classes.tabsRoot,
            }}
            value={tabValue}
            onChange={handleTabChange}
        >
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Tenants"
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Runs"
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Integrations"
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Users"
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Profile"
            />
        </Tabs>
    )
}
