import React from 'react'
import {
    BooleanField,
    TextField,
    DateField,
    useRecordContext,
} from 'react-admin'
import { CronLabel, SCHEDULEMAP, CustomerField } from '../../../common'
import LinkField from '../../../LinkField'
import { isEmpty } from 'lodash-es'
import BaseDatagrid from '../../../react_admin/BaseDatagrid'
import { RunPhaseField } from '../../../common'

const RunPhaseWrapper = (props) => {
    const record = useRecordContext(props)
    let source = 'status.lastRun.phase'
    //if the tenant record has a current run then source should be the status of that run
    if (record?.status?.currentRun) {
        source = 'status.currentRun.phase'
    }
    return <>{React.cloneElement(props.children, { source, record })}</>
}

export default ({ uidDisplay, customerColumnPath, ...props }) => {
    return (
        <BaseDatagrid rowClick="show" {...props}>
            <TextField
                source="integration.name"
                label={'INTEGRATION'}
                sortable={true}
            />
            <TextField label={'NAME'} source="name" sortable={true} />
            <DateField source="createdDate" label={'CREATED'} sortable={true} />
            {!isEmpty(uidDisplay) ? (
                <CustomerField
                    source={customerColumnPath}
                    label={'CUSTOMER'}
                    sortable={false}
                />
            ) : (
                <></>
            )}
            <CronLabel
                source="schedule"
                label={'SCHEDULE'}
                choices={SCHEDULEMAP}
                sortable={false}
            />
            <BooleanField source="paused" label="PAUSED" sortable={true} />
            <DateField
                source="status.lastRun.completionTime"
                label={'LAST RUN'}
                showTime={true}
                sortable={true}
                sortBy="tenant_status.last_run.completionTime"
            />
            <RunPhaseWrapper>
                <RunPhaseField label="STATUS" emptyOnUndefined={true} />
            </RunPhaseWrapper>
            <LinkField
                // a.detailLink is defined in the theme styles in appConfigs.js, to allow us to target
                // this field for the hover effect
                className="detailLink"
                basePath="tenants"
                redirect="show"
                sortable={true}
                variant="outlined"
            />
        </BaseDatagrid>
    )
}
