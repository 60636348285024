import React, { useState } from 'react'
import {
    useListController,
    useCreateController,
    TextField as RATextField,
    useDelete,
    useNotify,
    useRecordContext,
    ListContextProvider,
} from 'react-admin'
import {
    Divider,
    TextField,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    FormControl,
    OutlinedInput,
    InputLabel,
} from '@mui/material'
import RemoveCircleOutlined from '@mui/icons-material/RemoveCircleOutlined'
import FileCopy from '@mui/icons-material/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import BaseDatagrid from '../../react_admin/BaseDatagrid'
import { EmptyState } from '../../react_admin/EmptyState'

// field to render a delete button in a react-admin data grid
function DeleteField() {
    const { id } = useRecordContext()
    const [deleteOne] = useDelete()
    const [open, setOpen] = useState(false)
    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Are you sure you want to delete this key?
                </DialogTitle>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        className="filledButton"
                        onClick={() => deleteOne('apikeys', { id })}
                    >
                        I'm Sure
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton color="error" onClick={() => setOpen(true)}>
                <RemoveCircleOutlined />
            </IconButton>
        </>
    )
}

export default function ApiPortal() {
    const listControllerProps = useListController({
        basePath: '/apikeys',
        resource: 'apikeys',
        perPage: 10,
    })
    const [name, setName] = useState('')
    const [key, setKey] = useState('')
    const { save } = useCreateController({
        resource: 'apikeys',
    })
    const notify = useNotify()

    return (
        <div
            style={{
                maxWidth: '600px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                margin: '12px',
            }}
        >
            <Typography variant="h5">API Keys</Typography>
            <Typography variant="h6">Existing Keys</Typography>
            <Typography>
                These names refer to keys than have been authorized to access
                your account via the Pandium Customer API. The actual key is
                only available when it is created. After you delete an entry
                from this list, the API will reject requests signed with that
                key.
            </Typography>
            {/* list of active keys with delete buttons */}
            <ListContextProvider value={listControllerProps}>
                <BaseDatagrid
                    bulkActionButtons={false}
                    empty={
                        <EmptyState
                            sx={{
                                height: '200px',
                            }}
                            emptyStateText={
                                "You don't have any keys yet.\nCreate a new key below."
                            }
                        />
                    }
                >
                    <DeleteField label="Delete" />
                    <RATextField source="name" />
                    <RATextField source="created" />
                </BaseDatagrid>
            </ListContextProvider>

            <Divider />
            <Typography variant="h6">Create New Key</Typography>
            <Typography>
                Give your key a name that you can use to delete it later on.{' '}
            </Typography>
            {/* form to submit a name for a new key */}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    save(
                        { name },
                        {
                            onSuccess: ({ key }) => {
                                setName('')
                                setKey(key)
                                listControllerProps.refetch()
                            },
                            onFailure: () =>
                                notify('Error creating api key', {
                                    type: 'error',
                                }),
                        }
                    )
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <TextField
                    variant="outlined"
                    value={name}
                    label="name"
                    onChange={(e) => setName(e.target.value)}
                />

                <Button
                    size="small"
                    variant="contained"
                    className="filledButton"
                    disabled={!name}
                    type="submit"
                >
                    submit
                </Button>
            </form>

            {/* Dialog to show key after it is created */}
            <Dialog open={!!key} onClose={() => setKey('')}>
                <DialogTitle>
                    Please keep this key in a secure place.
                </DialogTitle>
                <DialogContent>
                    <FormControl
                        sx={{ m: 1, width: '50ch' }}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="display-key">Key</InputLabel>
                        <OutlinedInput
                            id="display-key"
                            value={key}
                            label="key"
                            multiline={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CopyToClipboard text={key}>
                                        <IconButton>
                                            <FileCopy />
                                        </IconButton>
                                    </CopyToClipboard>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className="filledButton"
                        onClick={() => setKey('')}
                    >
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
