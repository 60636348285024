import React from 'react'
import { Typography, Card, Button, CardContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CheckCircle } from '@mui/icons-material'
import { formatDate } from '@thefront/pandipackV2'

const useStyles = makeStyles({
    tenantCard: {
        height: '236px',
        margin: '40px 0 0 0px',
        width: '95%',
        display: 'inline-block',
        textAlign: 'left',
    },
    leftAlign: {
        textAlign: 'left',
        margin: '16px 16px 0px 16px',
    },
    tenantCardTop: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tenantCardContent: {
        padding: '0px',
        width: '70%',
    },
    buttonStyle: {
        width: '40%',
        padding: '8px',
        fontSize: 'small',
        fontWeight: 'bold',
        color: '#4A148C',
        margin: '0 0 0 16px',
        letterSpacing: '1.25px',
        fontFamily: 'Roboto Condensed',
    },
    integrationName: {
        whiteSpace: 'nowrap',
        paddingBottom: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tenantName: {
        height: '20px',
        paddingBottom: '10px',
        overflow: 'ellipsis',
    },
    imageStyle: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    imageContainer: {
        height: '5rem',
        width: '5rem',
        padding: '0 0 10px 10px',
        objectFit: 'contain',
    },
    statusText: {
        color: '#7ED321',
        fontSize: '14px',
        paddingBottom: '10px',
    },

    buttonsDiv: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    syncLabel: {
        marginRight: '10px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
    },
})

export default ({ img, integrationName, dateFormat }) => {
    const classes = useStyles()
    const src = img?.src ? img.src : img
    return (
        <Card className={classes.tenantCard}>
            <div className={classes.leftAlign}>
                <div className={classes.tenantCardTop}>
                    <CardContent className={classes.tenantCardContent}>
                        <Typography
                            className={classes.integrationName}
                            variant="h5"
                        >
                            {integrationName || 'Placeholder'}
                        </Typography>
                        <Typography className={classes.tenantName}>
                            User Name
                        </Typography>
                        <div className={classes.statusText}>
                            <CheckCircle />
                            <span>ACTIVE</span>
                        </div>
                        <Typography
                            color={'textSecondary'}
                            component="span"
                            className={classes.syncLabel}
                        >
                            Last Run:
                            {formatDate(new Date(), false, dateFormat)}
                        </Typography>
                    </CardContent>
                    <div className={classes.imageContainer}>
                        <img
                            src={src || '/logos/logo-placeholder-square.jpg'}
                            className={classes.imageStyle}
                            alt="tenantCardPreview"
                        />
                    </div>
                </div>
            </div>
            <div className={classes.buttonsDiv}>
                <Button
                    className={classes.buttonStyle}
                    onClick={() => {}}
                    variant={'outlined'}
                >
                    {' '}
                    SYNC NOW{' '}
                </Button>
                <MoreVertIcon />
            </div>
        </Card>
    )
}
