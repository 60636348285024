import {
    alphaNumericDash,
    required,
    isLowerCase,
    urlValidation,
} from '../../inputValidators'
import ConnectorSelectorForm from '../../ConnectorSelectorForm'
import { CronMapper } from '../../CronMapper'
import { SelectReleasesInput } from '../../common'

// used in internal integration create
export const connectorSelector = [
    {
        title: 'Connectors',
        subheading:
            'Add connectors to your integration. You must add at least one. Select Checkbox under Global to mark a Connector as having a shared global connection with all Tenants. You will be asked to connect it in the next step.',
        fields: {
            connectors: {
                type: 'custom',
                component: (props) => (
                    <ConnectorSelectorForm key="connectors" {...props} />
                ),
            },
        },
    },
]

// used in internal and external integraiton create
export const nameDetails = [
    {
        title: 'Details',
        fields: {
            name: {
                type: 'text',
                label: 'Integration ID',
                validate: [alphaNumericDash, required, isLowerCase],
                helperText:
                    'Unique identifier. Must be lowercase and kebab-case. IE: test-integration',
            },
            longName: {
                type: 'text',
                label: 'Integration Name',
                validate: [required],
            },
        },
    },
]

const remoteRepository = [
    {
        title: 'Remote Repository Settings',
        fields: {
            repositoryUrl: {
                type: 'text',
                label: 'Repository URL',
                validate: [urlValidation],
            },
            repositoryTrackingBranch: {
                type: 'text',
                label: 'Repository Tracking Branch',
            },
            repositoryPath: {
                type: 'text',
                label: 'Repository Path',
            },
        },
    },
]

const defaultRelease = [
    {
        title: 'Default Release',
        subheading:
            'Selected release will be considered the default release channel option when creating tenants. Default release behavior will be applied to newly installed tenants in/from the marketplace.',
        docLink:
            'https://docs.pandium.com/integration-hub/updating-an-integrations-release#setting-a-default-release',
        fields: {
            defaultReleaseId: {
                type: 'custom',
                component: SelectReleasesInput,
            },
            defaultReleaseChannel: {
                label:
                    'Default behavior for tenants created in the Integration Marketplace',
                type: 'radio',
                choices: [
                    {
                        id: '',
                        name: 'Static Default',
                        tooltip:
                            'Installed tenants use currently set default integration release. Release does not change on the tenant unless reset manually in the administrator platform.',
                    },
                    {
                        id: 'Default',
                        name: 'Dynamic Default',
                        tooltip:
                            'Installed tenants will be created using currently set default integration release. If the integration default release changes, the installed tenants will change their release(s) to match on the next run.',
                    },
                    {
                        id: 'Latest',
                        name: 'Latest',
                        tooltip:
                            'Tenants will always use the most recently-built release available for this integration.',
                    },
                ],
            },
        },
    },
]
const SyncSchedule = [
    {
        title: 'Sync Schedule',
        subheading:
            'Specify the sync schedule for the integration. Modify the default schedule and add custom schedules.',
        divider: false,
        fields: {
            syncScheduleTimes: {
                type: 'custom',
                component: (props) => (
                    <CronMapper setFormErrorState={() => {}} {...props} />
                ),
            },
            'marketplaceSettings.schedule': {
                type: 'cronPicker',
                label: 'Default schedule for a new tenant',
            },
            'marketplaceSettings.paused': {
                type: 'boolean',
                label: 'Paused sync schedule by default',
                helperText: {
                    false: 'Enable to default to paused sync schedule',
                },
            },
        },
    },
]

export const internalConfigsEdit = [
    ...remoteRepository,
    ...defaultRelease,
    ...SyncSchedule,
]
export const internalConfigsCreate = [...remoteRepository, ...SyncSchedule]

export const externalConfigs = [
    {
        title: 'External Integration Settings',
        divider: false,
        fields: {
            'marketplaceSettings.externalURL': {
                label: 'External Integration URL',
                type: 'text',
                helperText: 'Full URL path required',
                validate: [urlValidation, required],
            },
            'marketplaceSettings.externalId': {
                type: 'text',
                label: 'External Integration ID',
                helperText:
                    "The ID you will send with your marketplace JWT in the 'ili' field to identify this integration",
            },
            'marketplaceSettings.linkTarget': {
                type: 'dropDown',
                label: 'External Integration Link Target',
                choices: [
                    { id: '_blank', name: 'New Tab (_blank)' },
                    { id: '_self', name: 'Same Frame (_self)' },
                    { id: '_parent', name: 'Parent Frame (_parent)' },
                    { id: '_top', name: 'Entire Window (_top)' },
                ],
            },
        },
    },
]
