import { Box, Typography } from '@mui/material'
import React from 'react'

export const CustomLabel = ({ title, subtitle }) => {
    return (
        <Box sx={{ margin: '14px 0 10px 0' }}>
            <Typography
                sx={{
                    color: '#6d6d6d',
                    fontFamily: 'Roboto Condensed',
                    fontSize: '21px',
                    display: 'block',
                    margin: '10px 0 0 0',
                }}
            >
                {title}
            </Typography>
            {subtitle && (
                <Typography
                    sx={{
                        display: 'block',
                        margin: '16px 0 36px 0px',
                        fontFamily: 'Roboto Condensed',
                    }}
                >
                    {subtitle}
                </Typography>
            )}
        </Box>
    )
}
