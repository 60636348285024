import React from 'react'
import { Button, Grid } from '@mui/material'
import RunStatusSelectFilter from '../List/Filters/RunStatusSelectFilter'

/**
 * Filter toolbar!
 * @param filters
 * @param setFilters
 * @param props
 * @returns {*}
 * @constructor
 */
export default ({ setFilters, filterValues }) => {
    const handleChange = (source, e) => {
        setFilters({ ...filterValues, [source]: e.target.value })
    }

    return (
        <Grid
            sx={{ margin: '0 10px 10px 0' }}
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item sx={{ padding: '12px 12px 0 12px' }}>
                <RunStatusSelectFilter
                    handleChange={handleChange}
                    filters={filterValues}
                />
            </Grid>
            <Grid item sx={{ padding: '12px 12px 0 12px' }}>
                <Button
                    onClick={() => setFilters({})}
                    sx={{
                        fontFamily: 'RobotoCondensedBold',
                        letterSpacing: '1.5px',
                        color: '#626FFC',
                    }}
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    )
}
