import React, { useState } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    RadioGroup,
    Radio,
    FormControlLabel,
    Tooltip,
    Box,
} from '@mui/material'
import { HelpOutline } from '@mui/icons-material'

const FormControlLabelWithTooltip = ({ value, control, label, tooltip }) => {
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <FormControlLabel value={value} control={control} label={label} />
            <Tooltip
                PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            fontSize: '14px',
                            padding: '8px ',
                            backgroundColor: 'rgba(0,0,0,0.87)',
                        },
                    },
                }}
                title={tooltip}
            >
                <HelpOutline sx={{ fontSize: 20, color: 'DimGray' }} />
            </Tooltip>
        </Box>
    )
}

export const RerunDialog = ({
    open,
    setOpen,
    runId,
    tenantId,
    persistContextOnly = false,
    rerunCustomConfiguration,
}) => {
    const dataProvider = useDataProvider()
    const redirect = useRedirect()
    const navigate = useNavigate()
    const notify = useNotify()
    const [rerunType, setRerunType] = useState(
        persistContextOnly ? 'custom' : 'original'
    )
    const [page, setPage] = useState(persistContextOnly ? 1 : 0)
    const [persistContext, setPersistContext] = useState(false)

    const close = () => {
        setOpen(false)
        if (!persistContextOnly) {
            setPage(0)
        }
    }

    const next = () => {
        if (rerunType === 'custom') {
            redirect('/runs/' + runId + '/edit')
        } else {
            setPage(1)
        }
    }

    const rerun = () => {
        close()
        const custom = rerunCustomConfiguration
            ? rerunCustomConfiguration()
            : {}
        const payload = {
            rerunType: rerunType,
            ...custom,
        }
        if (!persistContext) {
            payload.persistContext = false
        }

        dataProvider
            .RERUN_INITIATED('runs', {
                runId: runId,
                data: payload,
            })
            .then((res) => {
                if (res.data) {
                    notify('Rerunning')
                    if (tenantId) {
                        redirect(`/tenants/${tenantId}/show`)
                    } else {
                        navigate(-1)
                    }
                }
            })
            .catch((err) => {
                notify('Error creating run', { type: 'error' })
                console.log(
                    `Error initiating rerun for run ID ${runId}: ${err}`
                )
            })
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={'sm'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <DialogTitle sx={{ fontWeight: 'semibold' }}>
                Are you sure you want to rerun this run?
            </DialogTitle>
            <DialogContent>
                {page === 0 ? (
                    <>
                        <Typography sx={{ mb: 2 }}>
                            How would you like to configure this rerun?
                        </Typography>
                        <RadioGroup
                            sx={{ ml: 1 }}
                            value={rerunType}
                            onChange={(e) => {
                                setRerunType(e.target.value)
                            }}
                        >
                            <FormControlLabelWithTooltip
                                value={'original'}
                                control={<Radio />}
                                label="Rerun with original configuration"
                                tooltip="Use the integration release, STDOUT, and configs that the original run received."
                            />
                            <FormControlLabelWithTooltip
                                value={'current'}
                                control={<Radio />}
                                label="Rerun with current configuration of tenant"
                                tooltip="Use the integration release, STDOUT, and configs that are currently set on the tenant."
                            />
                            <FormControlLabelWithTooltip
                                value={'custom'}
                                control={<Radio />}
                                label="Rerun with custom configuration"
                                tooltip="Customize the integration release, STDOUT, and configs for this rerun, with default values populated from the original run."
                            />
                        </RadioGroup>
                    </>
                ) : (
                    <>
                        <Typography sx={{ mb: 2 }}>
                            Do you want to save the STDOUT and context from this
                            rerun to be used in future runs?
                        </Typography>
                        <RadioGroup
                            sx={{ ml: 1, mb: 2 }}
                            value={persistContext}
                            onChange={(e) => {
                                setPersistContext(e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No (Default)"
                            />
                        </RadioGroup>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box>
                    {page === 0 || persistContextOnly ? (
                        <Button onClick={close}>Cancel</Button>
                    ) : (
                        <Button onClick={() => setPage(0)}>Back</Button>
                    )}
                    <Button onClick={page === 0 ? next : rerun}>
                        {page === 0 ? 'Next' : 'Rerun'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
