import React from 'react'
import { Edit } from 'react-admin'
import { Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PageTitle } from '../../../common'
import PartnerEditForm from './PartnerEditForm'
import { useParams } from 'react-router-dom'

const usePartnerEditStyles = makeStyles({
    edit: {
        margin: '20px',
    },
})

export default () => {
    const classes = usePartnerEditStyles()
    const { id: partnerId } = useParams()
    return (
        <div>
            <PageTitle title={'Edit Partner'} />
            <Divider className="pageHead" />
            <Edit
                id={partnerId}
                actions={null}
                resource="partners"
                basePath="partners/"
                component="div"
                className={classes.edit}
            >
                <PartnerEditForm partnerId={partnerId} resource={'partners'} />
            </Edit>
        </div>
    )
}
