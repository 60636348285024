import React from 'react'
import { Box, Typography, Divider, IconButton } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopy from '@mui/icons-material/FileCopy'

export default function CliDownloadPage() {
    const staticUrl =
        process.env.REACT_APP_STATIC_URI ||
        `https://static.${window.location.hostname
            .split('.')
            .slice(1)
            .join('.')}`
    return (
        <Box
            sx={{
                maxWidth: '720px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                margin: '12px',
            }}
        >
            <Divider sx={{ marginBottom: '12px', marginTop: '12px' }} />

            <Typography variant="h5">Download the Pandium CLI</Typography>
            <Typography variant="subtitle1">
                The CLI allows developers to connect to Pandium from their local
                environment in order to securely test integration scripts using
                live data. Paste these commands into your console to install the
                binary for your system. If you don't see your platform of choice
                listed, please let us know.
            </Typography>

            {[
                {
                    name: 'MacOS aarch64 (Apple Silicon)',
                    path: '/cli/macos/aarch64/pandium',
                },
                {
                    name: 'MacOS x86_64 (Intel)',
                    path: '/cli/macos/x86_64/pandium',
                },
                // {
                //     name: 'Linux x86_64',
                //     path: '/cli/linux/x86_64/pandium',
                // },
                // {
                //     name: 'Windows x86_64',
                //     path: '/cli/windows/x86_64/pandium.exe',
                // },
            ].map(({ name, path }) => {
                const text = `curl ${staticUrl}${path} --output pandium
chmod +x pandium
sudo mv pandium /usr/local/bin/`
                return (
                    <Box margin={'8px'}>
                        <Typography variant={'h6'}>{name}</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: 'lightgrey',
                                fontSize: 'medium',
                                padding: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <code>
                                    <pre>{text}</pre>
                                </code>
                            </Box>
                            <Box>
                                <CopyToClipboard text={text}>
                                    <IconButton>
                                        <FileCopy />
                                    </IconButton>
                                </CopyToClipboard>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}
