import { put, takeEvery, all } from 'redux-saga/effects'
import { fetchOrganization, fetchThemeConfigs } from './appConfigs'

function* fetchOrg(action) {
    try {
        let payload
        const { isParentOrg, orgId, parentId, isSuperUser } = action.payload
        if (!isParentOrg) {
            // TODO: this should just be one API call
            const parentOrg = yield fetchOrganization(parentId)
            const myOrg = yield fetchOrganization(orgId)
            payload = {
                isParentOrg,
                orgId,
                name: myOrg.name,
                topLogo: parentOrg.settings.primaryLogo,
                parentName: parentOrg.name,
                customSubmissionForm: parentOrg.settings.submissionForm,
                marketplaceBaseUrl: parentOrg.settings.marketplaceBaseUrl,
                approvalStatus: myOrg.approval_status,
                intakeForm: parentOrg.partner_intake_form,
                isSuperUser: isSuperUser,
                hasGallery: false,
            }

            if (myOrg.approval_status.toLowerCase() === 'approved') {
                const themeConfigs = yield fetchThemeConfigs(parentId)
                payload.theme = themeConfigs[0]
            }
        } else {
            const myOrg = yield fetchOrganization(orgId)
            const themeConfigs = yield fetchThemeConfigs(orgId)
            payload = {
                entitlements: myOrg.entitlements,
                isParentOrg,
                orgId,
                name: myOrg.name,
                topLogo: null,
                parentName: null,
                marketplaceBaseUrl: myOrg.settings.marketplaceBaseUrl,
                customSubmissionForm: myOrg.settings.submissionForm,
                // TODO will possibly need to update this when we have multiple themes
                theme: themeConfigs[0],
                isSuperUser: isSuperUser,
                // TODO get this from database once we have better account settings
                hasGallery: ['gwt', 'foundation', 'preview'].includes(
                    myOrg.name.toLowerCase()
                ),
            }
        }
        yield put({ type: 'UPDATE_ORG', payload })
    } catch (e) {
        console.error('error fetching organization', e.error)
    }
}

export function* orgSaga() {
    yield takeEvery('ORG_FETCH_REQUESTED', fetchOrg)
}

export default function* rootSaga() {
    yield all([orgSaga()])
}
