import React from 'react'
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { useGetList } from 'react-admin'

const integrationSelectFilterStyles = {
    selectField: {
        height: '38px',
        borderRadius: 0,
        width: 'auto',
        '& > div': {
            fontSize: '13px',
            fontWeight: 'bold',
        },
        '&:hover': {
            '& > fieldset': {
                border: '2px solid black',
            },
        },
        minWidth: '150px',
        '& div:focus': { backgroundColor: 'white' },
    },
}

export default (props) => {
    const { handleChange, filters, tenantList, isSuperUser } = props
    const pandiumFilter = !isSuperUser ? { type__not_equal: 'PANDIUM' } : {}
    const { data, isLoading: loading } = useGetList('integrations', {
        pagination: { page: 1, perPage: 500 },
        filter: pandiumFilter,
    })

    if (loading) {
        return <CircularProgress size={28} sx={{ mt: 1 }} />
    }

    return (
        <FormControl variant="outlined">
            <InputLabel margin="dense" variant="outlined" size="small">
                Integration ID
            </InputLabel>
            <Select
                onChange={(e) => {
                    tenantList
                        ? handleChange('integration_id', e)
                        : handleChange('integrationName', e)
                }}
                value={
                    tenantList
                        ? filters['integration_id'] || ''
                        : filters['integrationName'] || ''
                }
                sx={integrationSelectFilterStyles.selectField}
                label={'Integration ID'}
            >
                {Object.keys(data).map((integration, idx) => (
                    <MenuItem
                        key={idx}
                        value={
                            tenantList
                                ? data[integration].id
                                : data[integration].name
                        }
                    >
                        {' '}
                        {data[integration].name}{' '}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
